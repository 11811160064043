import classNames from "classnames";
import Link from "next/link";
import React, { useRef, useState } from "react";
import { BrandContext } from "../../../../contexts/BrandContext";
import { Brand } from "../../../../models/entities/Brand";
import { User } from "../../../../models/entities/User";
import { BrandButtonColorStyle } from "../../../../utils/enums";
import BrandTooltip from "../../BrandTooltip";
import CustomBrandTooltip from "../../CustomBrandTooltip";
import { MilestoneProgressType } from "../../MilestoneProgress/MilestoneProgress";
import Dropdown, {
  NextJSHeadlessLink,
} from "../../custom-components/menu/Dropdown";
import { DashboardItemsType } from "../UserDashboard";
import UserDashboardLogoutSection from "./UserDashboardLogoutSection";
import UserProfileMilestoneSection from "./UserProfile&MilestoneSection";

interface UserDashboardDesktopViewProps {
  collapsed: boolean;
  isAdmin: boolean;
  isUser: boolean;
  setCollapsed: Function;
  userData: User;
  brandUserDashBoardConfigs: {
    tiers: any;
    tires_value: any;
    suggestion_email: any;
  };
  selectedMenu: string;
  navBarItems: DashboardItemsType[];
  setModalType: Function;
  setIsSupportModalOpen: Function;
  setIsDisconnectedModalOpen: Function;
  brand: Brand;
}

const UserDashboardDesktopView: React.FC<UserDashboardDesktopViewProps> = ({
  collapsed,
  isAdmin,
  isUser,
  setCollapsed,
  userData,
  brandUserDashBoardConfigs,
  selectedMenu,
  navBarItems,
  setIsSupportModalOpen,
  setModalType,
  brand,
  setIsDisconnectedModalOpen,
}) => {
  const brandValue = React.useContext(BrandContext);

  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>(0);

  const milestoneProgressData: MilestoneProgressType = [
    {
      tag: brandUserDashBoardConfigs.tiers?.bottom,
      targetPoints: brandUserDashBoardConfigs.tires_value?.bottom,
    },
    {
      tag: brandUserDashBoardConfigs.tiers?.middle,
      targetPoints: brandUserDashBoardConfigs.tires_value?.middle,
    },
    {
      tag: brandUserDashBoardConfigs.tiers?.top,
      targetPoints: brandUserDashBoardConfigs.tires_value?.top,
    },
  ];

  return (
    <div
      className={`rounded-r-[1.25rem] transition-[width] duration-500 tracking-wider overflow-y-auto md:block shadow-primaryCardShadow hidden hideScrollBar ${
        collapsed ? "w-[5rem]" : "w-[18.75rem]"
      } h-full bg-dashboard `}
    >
      <div className={`!text-xs h-full`}>
        <div className="flex flex-col justify-between h-full max-h-[1080px] space-y-2">
          <UserProfileMilestoneSection
            isUser={isUser}
            isAdmin={isAdmin}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            userData={userData}
            brand={brand}
            milestoneProgressData={milestoneProgressData}
          />
          {/* Navbar Items */}
          <div
            id="menu"
            className={`${
              !collapsed ? "px-8" : "px-1"
            } transition-[padding] duration-500 text-[16px] pt-2 !my-2 `}
          >
            {navBarItems
              .filter((i) => !i.isHidden)
              .map((item: DashboardItemsType) => {
                // if Link
                const linkOrButtonChild = (
                  <>
                    {/* Pass id as undefined, for no tooltip */}
                    <BrandTooltip
                      className="z-[60]"
                      tooltip={item.label}
                      id={collapsed ? `${item.value}_menu_tooltip` : undefined}
                    >
                      <span
                        className={`relative p-2 transition-colors rounded-full ${
                          item.regex?.test(selectedMenu || "")
                            ? `drop-shadow-dashboardSelected text-textButton ${
                                brandValue.brandButtonColorStyle ===
                                BrandButtonColorStyle.GRADIENT
                                  ? "gradient-button"
                                  : "bg-primaryButton"
                              }`
                            : ""
                        }`}
                      >
                        {item.icon}
                      </span>
                    </BrandTooltip>
                    {!collapsed && (
                      <span className="py-2 font-semibold whitespace-nowrap">
                        {item.label}
                      </span>
                    )}
                  </>
                );

                if (item.defaultRoutePath) {
                  return (
                    <div key={item.value} className={`w-full`}>
                      {!item.openInNewTab ? (
                        <Link
                          href={item.defaultRoutePath}
                          legacyBehavior
                          onClick={
                            item.isDisabled
                              ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }
                              : undefined
                          }
                        >
                          <a
                            className={`flex space-x-5 items-center w-full !justify-start px-4 py-1 ${
                              item.isDisabled
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                            onClick={
                              item.isDisabled
                                ? (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                : undefined
                            }
                          >
                            {linkOrButtonChild}
                          </a>
                        </Link>
                      ) : (
                        <a
                          href={item.defaultRoutePath}
                          className={`flex space-x-5 items-center w-full !justify-start px-4 py-1 ${
                            item.isDisabled
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                          target="_blank"
                          rel="noreferrer noopener"
                          onClick={
                            item.isDisabled
                              ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }
                              : undefined
                          }
                        >
                          {linkOrButtonChild}
                        </a>
                      )}
                    </div>
                  );
                } else if (!item.openDropdown && item.onClick) {
                  return (
                    <div key={item.value} className={`w-full`}>
                      <button
                        className={`flex space-x-5 items-center w-full !justify-start px-4 py-1 ${
                          item.isDisabled ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={item.isDisabled}
                        onClick={item.onClick}
                      >
                        {linkOrButtonChild}
                      </button>
                    </div>
                  );
                } else if (
                  item.openDropdown &&
                  item.dropdownItems &&
                  item.dropdownItems?.length > 0
                ) {
                  return (
                    <div key={item.value}>
                      <Dropdown
                        id={"more_drop_down"}
                        disabled={item.isDisabled}
                        menuItemsClassName={`${
                          collapsed ? "left-[4.5rem]" : "left-[12rem]"
                        } -translate-y-full rounded-[1.25rem] z-50 w-44 text-xs lg:text-sm font-medium grid grid-cols-1 shadow-primaryCardShadow`}
                        menuItemsStyle={{
                          top: +top + 20,
                        }}
                        items={item.dropdownItems
                          .filter((i) => !i.isHidden)
                          .map((dropItem) => {
                            const labelChild = (
                              <>
                                {dropItem.icon && (
                                  <span
                                    className={`p-2 rounded-full ${
                                      dropItem.regex?.test(selectedMenu || "")
                                        ? `drop-shadow-dashboardSelected text-textButton ${
                                            brandValue.brandButtonColorStyle ===
                                            BrandButtonColorStyle.GRADIENT
                                              ? "gradient-button"
                                              : "bg-primaryButton"
                                          }`
                                        : ""
                                    } `}
                                  >
                                    {dropItem.icon}
                                  </span>
                                )}
                                <span className={`py-2 font-semibold`}>
                                  {dropItem.label}
                                </span>
                              </>
                            );

                            return dropItem.defaultRoutePath ? (
                              // If Link
                              !dropItem.openInNewTab ? (
                                <NextJSHeadlessLink
                                  href={dropItem.defaultRoutePath}
                                  key={dropItem.value}
                                  onClick={
                                    dropItem.isDisabled
                                      ? (e: any) => {
                                          // If link is disabled
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }
                                      : undefined
                                  }
                                  className={classNames(
                                    "flex space-x-2 items-center w-full !justify-start px-4 py-2",
                                    {
                                      "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                        dropItem.isDisabled,
                                    }
                                  )}
                                >
                                  {labelChild}
                                </NextJSHeadlessLink>
                              ) : (
                                <a
                                  href={dropItem.defaultRoutePath}
                                  key={dropItem.value}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  onClick={
                                    dropItem.isDisabled
                                      ? (e: any) => {
                                          // If link is disabled
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }
                                      : undefined
                                  }
                                  className={classNames(
                                    "flex space-x-2 tracking-normal items-center w-full !justify-start px-4 py-2",
                                    {
                                      "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                        dropItem.isDisabled,
                                    }
                                  )}
                                >
                                  {labelChild}
                                </a>
                              )
                            ) : (
                              // If button
                              <button
                                disabled={dropItem.isDisabled}
                                onClick={dropItem.onClick}
                                className={classNames(
                                  "flex space-x-2 items-center w-full !justify-start px-4 py-2",
                                  {
                                    "!cursor-not-allowed !opacity-50 hover:!bg-transparent":
                                      dropItem.isDisabled,
                                  }
                                )}
                              >
                                {labelChild}
                              </button>
                            );
                          })}
                      >
                        {/* Dropdown Button */}
                        <div
                          ref={ref}
                          key={item.value}
                          id={`${item.value}-menu`}
                          className={`w-full rounded-none rounded-r-full transition-colors duration-300 `}
                          onClick={(e) =>
                            setTop(e.currentTarget.getBoundingClientRect().top)
                          }
                        >
                          <span className="flex space-x-5 items-center w-full !justify-start px-4 py-1">
                            <span
                              id={`${item.value}_menu_tooltip`}
                              className="p-2 rounded-full"
                            >
                              {item?.icon}
                            </span>
                            {!collapsed && (
                              <span className="py-2 font-semibold">
                                {item.label}
                              </span>
                            )}
                          </span>
                        </div>
                      </Dropdown>
                      {collapsed && (
                        <CustomBrandTooltip
                          tooltip={item.label}
                          id={`${item.value}_menu_tooltip`}
                        />
                      )}
                    </div>
                  );
                }
              })}
          </div>

          {/* Logout and text */}
          <UserDashboardLogoutSection
            collapsed={collapsed}
            isAdmin={isAdmin}
            isUser={isUser}
            setIsDisconnectedModalOpen={setIsDisconnectedModalOpen}
            brand={brand}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserDashboardDesktopView);
