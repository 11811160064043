import { Admin } from "../../models/entities/Admin";
import { BrandMetadata, RawBrand } from "../../models/entities/Brand";
import { BrandConfig } from "../../models/entities/BrandConfig";
import BrandSocialCredential from "../../models/entities/BrandSocialsCredentials";
import { Engagement } from "../../models/entities/Engagement";
import { BrandConfigKeys } from "../../utils/enums";
import { webStorageService } from "../WebStorageService";
import { baseApiService } from "./BaseApiService";

class BrandService {
  static getInstance(): BrandService {
    return new BrandService();
  }

  async metadata(): Promise<{ metadata: BrandMetadata }> {
    const response = await baseApiService.get("/brands/metadata", {
      extras: {
        useAuth: false,
      },
    });
    return { metadata: (response as any).data };
  }

  async list(): Promise<{ brand: RawBrand }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/brands", {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { brand: (response as any).data.brand };
  }

  async create(payload: {
    title: string;
    primary_color: string;
    wallet_address: string;
  }): Promise<{
    brand: RawBrand;
    logo_signed_url: string;
    banner_signed_url: string;
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("/brands", payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      brand: response.data.brand,
      logo_signed_url: response.data.logo_signed_url,
      banner_signed_url: response.data.banner_signed_url,
    };
  }
  async update(
    id: number,
    payload: {
      title: string;
      privacy_policy: string | null;
      terms: string | null;
      currency: string | null;
      website_url: string | null;
    }
  ): Promise<{ brand: RawBrand }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/brands`,
      {
        title: payload.title,
        privacy_policy_url: payload.privacy_policy,
        terms_and_condition_url: payload.terms,
        currency: payload.currency,
        website_url: payload.website_url,
      },
      {
        params: {
          id: id.toString(),
        },
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { brand: response.data.brand };
  }

  async updateLogo(
    id: number
  ): Promise<{ brand_config: BrandConfig; logo_signed_url: string }> {
    const response = await baseApiService.put(`brands/logo`);
    return {
      brand_config: response.data.brand_config,
      logo_signed_url: response.data.logo_signed_url,
    };
  }

  async updateShareImage(
    id: number
  ): Promise<{ brand_config: BrandConfig; shareable_signed_url: string }> {
    const response = await baseApiService.put(`brands/shareable`);
    return {
      brand_config: response.data.brand_config,
      shareable_signed_url: response.data.shareable_signed_url,
    };
  }
  async updateFavicon(
    id: number
  ): Promise<{ brand_config: BrandConfig; favicon_signed_url: string }> {
    const response = await baseApiService.put(`brands/favicon`);
    return {
      brand_config: response.data.brand_config,
      favicon_signed_url: response.data.favicon_signed_url,
    };
  }
  async updateBanner(
    id: number
  ): Promise<{ brand_config: BrandConfig; banner_signed_url: string }> {
    const response = await baseApiService.put(`brands/banner`);
    return {
      brand_config: response.data.brand_config,
      banner_signed_url: response.data.banner_signed_url,
    };
  }
  async addAdmin(payload: {
    wallet_address?: string;
    username: string;
    email?: string;
  }): Promise<{ admin: Admin }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("/brands/admins", payload, {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return {
      admin: response.data.admin,
    };
  }
  async editAdmin(req: {
    id: any;
    payload: {
      wallet_address?: string;
      username?: string;
      email?: string;
    };
  }): Promise<{ admin: Admin }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/admin/admins/${req.id}`,
      req.payload,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      admin: response.admin,
    };
  }

  async getBrandSocialCredentials(): Promise<{
    brand_social_credentials: BrandSocialCredential[];
  }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/brands/social-credentials", {
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { brand_social_credentials: response.data.brand_social_credentials };
  }

  async getAdminList(payload: {
    search: string;
  }): Promise<{ admins: Admin[] }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.get("/admin/admins", {
      params: {
        search: payload.search ?? "",
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { admins: response.data.admins };
  }
  async deleteAdmin(id: number): Promise<{ success: boolean }> {
    const response = await baseApiService.delete(`/admin/admins/${id}`);
    return { success: response.data.success };
  }

  async updateBrandSocialCredentials(payload: {
    provider: string;
    access_token?: string;
    client_id?: string;
    client_secret?: string;
    is_paid?: boolean;
    clientEmail?: string;
    privateKey?: string;
    projectId?: string;
  }): Promise<{ brand_social_credential: BrandSocialCredential }> {
    const response = await baseApiService.put(`/brands/social-credentials`, {
      ...payload,
    });
    return { brand_social_credential: response.data.brand_social_credential };
  }
  async getBrandLatestTweet(payload: {
    urls: string[];
  }): Promise<{ engagements: Engagement[] }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.post("/engagements/latest", null, {
      params: {
        urls: payload.urls ?? [],
      },
      extras: {
        useAuth: authToken ? true : false,
      },
    });
    return { engagements: response.data.engagements };
  }

  async updateConfig(payload: {
    id: number;
    value: any;
  }): Promise<{ brand_config: BrandConfig }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/brands/${payload.id}/config`,
      {
        value: payload.value,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { brand_config: response.data.brand_config };
  }
  async uploadAllowlist(payload: {
    file: File;
    config: string;
  }): Promise<{ success: boolean }> {
    const authToken = await webStorageService.getAuthToken();
    let formData = new FormData();

    if (payload.config === BrandConfigKeys.EMAIL_LOGIN_CONFIG) {
      formData.append("whitelist_emails", payload.file);
    } else {
      formData.append("whitelist_wallets", payload.file);
    }

    const response = await baseApiService.post(
      "admin/brands/add-whitelists",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      success: response.success,
    };
  }

  async pwaAppearanceConfig(payload: {
    name: string;
    short_name: string;
    description: string;
    scope: string;
    display: string;
    icon: {
      image: string;
      is_new_uploaded: boolean;
    };
    start_url: string;
    background_color: string;
    theme_color: string;
  }): Promise<{ updated_config: BrandConfig }> {
    const authToken = await webStorageService.getAuthToken();

    const convertImageToBase64 = (imageUrl: any): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(imageUrl);
      });
    };

    // Convert image to base64
    const base64Image = payload.icon.image
      ? await convertImageToBase64(payload.icon.image)
      : null;

    const response = await baseApiService.put(
      "/brands/pwa",
      {
        name: payload.name,
        short_name: payload.short_name,
        description: payload.description,
        scope: payload.scope,
        display: payload.display,
        icon: {
          image: base64Image,
          is_new_uploaded: payload.icon.is_new_uploaded,
        },
        start_url: payload.start_url,
        background_color: payload.background_color,
        theme_color: payload.theme_color,
      },
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return {
      updated_config: response.data.brand_config,
    };
  }
  async getManifestJSON(payload: {
    url: string;
  }): Promise<{ manifest_json: any }> {
    const response = await baseApiService.get(payload.url, {
      extras: {
        useAuth: false,
      },
    });
    return { manifest_json: response };
  }

  async updateBulkConfigs(payload: {
    configs: { id: number; value: any }[];
  }): Promise<{ brand_configs: BrandConfig[] }> {
    const authToken = await webStorageService.getAuthToken();
    const response = await baseApiService.put(
      `/brands/update-bulk-configs`,
      payload,
      {
        extras: {
          useAuth: authToken ? true : false,
        },
      }
    );
    return { brand_configs: response.data.brand_configs };
  }
}

export const brandService = BrandService.getInstance();
