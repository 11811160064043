import { Admin } from "../../models/entities/Admin";
import { Brand } from "../../models/entities/Brand";
import { User } from "../../models/entities/User";
import {
  AUTH_CHECK_VERIFICATION,
  AUTH_CHECK_VERIFICATION_COMPLETED,
  AUTH_CHECK_VERIFICATION_ERROR,
  AUTH_LOGIN_COMPLETED,
  AUTH_LOGIN_EMAIL_RESEND,
  AUTH_LOGIN_EMAIL_RESEND_COMPLETED,
  AUTH_LOGIN_EMAIL_RESEND_ERROR,
  AUTH_LOGIN_EMAIL_RESEND_RESET,
  AUTH_LOGIN_ERROR_RESET,
  AUTH_LOGIN_HALF,
  AUTH_LOGIN_HALF_COMPLETED,
  AUTH_LOGIN_HALF_DISABLED_UI,
  AUTH_LOGIN_HALF_EMAIL_SENT,
  AUTH_LOGIN_HALF_ERROR,
  AUTH_LOGIN_SHOW_MODAL_UI,
  AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN,
  AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_COMPLETED,
  AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_ERROR,
  AUTH_SIGNATURE_REJECTED,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_EMAIL_COMPLETED,
  AUTH_VERIFY_EMAIL_ERROR,
  AUTH_VERIFY_SIGNATURE,
  AUTH_VERIFY_SIGNATURE_ERROR,
  RESET_APP_STATE,
  SHOW_EMAIL_MARKETING_MODAL,
} from "./actions.constants";

export const authLoginHalfAction = (payload: {
  address?: string;
  reCaptchaToken?: string;
  email?: string;
  invite_id?: string;
  referral_code?: string;
}) => ({
  type: AUTH_LOGIN_HALF,
  payload,
});

export const authLoginHalfCompletedAction = (data: { nonce: string }) => ({
  type: AUTH_LOGIN_HALF_COMPLETED,
  data,
});
export const authLoginHalfDisabledUIAction = (payload: {
  req: {
    is_disabled: boolean;
  };
}) => ({
  type: AUTH_LOGIN_HALF_DISABLED_UI,
  payload,
});

export const authLoginModalUIAction = (payload: {
  req: {
    show_modal: boolean;
  };
}) => ({
  type: AUTH_LOGIN_SHOW_MODAL_UI,
  payload,
});
export const authLoginHalfEmailSentAction = () => ({
  type: AUTH_LOGIN_HALF_EMAIL_SENT,
});

export const authLoginHalfErrorAction = (message: string) => ({
  type: AUTH_LOGIN_HALF_ERROR,
  payload: message,
});

export const authVerifyEmailAction = (payload: {
  code: string;
  isTypeOTP?: boolean;
}) => ({
  type: AUTH_VERIFY_EMAIL,
  payload,
});

export const authVerifyEmailCompletedAction = (payload: {
  success: boolean;
}) => ({
  type: AUTH_VERIFY_EMAIL_COMPLETED,
  payload,
});
export const authVerifyEmailErrorAction = (payload: { message: string }) => ({
  type: AUTH_VERIFY_EMAIL_ERROR,
  payload,
});

export const authLoginErrorRestAction = () => ({
  type: AUTH_LOGIN_ERROR_RESET,
});
export const authCheckVerificationAction = (payload: {
  code: string;
  referral_code?: string;
  invite_id?: string;
  otp?: string;
}) => ({
  type: AUTH_CHECK_VERIFICATION,
  payload,
});

export const authCheckVerificationCompletedAction = () => ({
  type: AUTH_CHECK_VERIFICATION_COMPLETED,
});

export const authCheckVerificationErrorAction = (message: string) => ({
  type: AUTH_CHECK_VERIFICATION_ERROR,
  payload: message,
});

export const authVerifySignatureAction = (payload: {
  address: string;
  signature: string;
}) => ({ type: AUTH_VERIFY_SIGNATURE, payload });

export const authLoginCompletedAction = (data: {
  data: {
    user: User | null;
    admin: Admin | null;
  };
  brand: Brand;
  token: string;
}) => ({ type: AUTH_LOGIN_COMPLETED, data });

export const authVerifySignatureErrorAction = (message: string) => ({
  type: AUTH_VERIFY_SIGNATURE_ERROR,
  payload: message,
});

export const authSignatureRejectedAction = () => ({
  type: AUTH_SIGNATURE_REJECTED,
});

export const authLoginEmailResendAction = (payload: { code: string }) => ({
  type: AUTH_LOGIN_EMAIL_RESEND,
  payload,
});

export const authLoginEmailResendCompletedAction = (payload: {
  res: {
    isEmailResent: boolean;
  };
}) => ({ type: AUTH_LOGIN_EMAIL_RESEND_COMPLETED, payload });

export const authLoginEmailResendErrorAction = (payload: {
  message: string;
}) => ({ type: AUTH_LOGIN_EMAIL_RESEND_ERROR, payload });

export const authLoginEmailResendResetAction = () => ({
  type: AUTH_LOGIN_EMAIL_RESEND_RESET,
});

export const resetAppStateAction = () => ({
  type: RESET_APP_STATE,
});

export const authWalletAddressRejectSignAction = (payload: {
  address: string;
}) => ({
  type: AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN,
  payload,
});

export const authWalletAddressRejectSignActionCompleted = (payload: {
  res: {
    success: boolean;
  };
}) => ({
  type: AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_COMPLETED,
  payload,
});

export const authWalletAddressRejectSignActionError = (payload: {
  res: {
    message: string;
  };
}) => ({
  type: AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_ERROR,
  payload,
});

export const showMarketingModalAction = (payload: {
  res: { open: boolean };
}) => ({
  type: SHOW_EMAIL_MARKETING_MODAL,
  payload,
});
