import { Transition } from "@headlessui/react";
import router from "next/router";
import { CaretCircleDoubleLeft, PencilSimple } from "phosphor-react";
import React from "react";
import { useSelector } from "react-redux";
import NextRouteConfig from "../../../../../next-routes/NextRouteConfig";
import { BrandContext } from "../../../../contexts/BrandContext";
import { Brand } from "../../../../models/entities/Brand";
import { User } from "../../../../models/entities/User";
import { UserState } from "../../../../redux/reducers/user.reducer";
import { userSelector } from "../../../../redux/selectors/app.selectors";
import Utils from "../../../../utils/Utils";
import MilestoneProgress, {
  MilestoneProgressType,
} from "../../MilestoneProgress/MilestoneProgress";
import BrandHeading from "../../custom-components/heading/BrandHeading";
import ImageWrapper from "../../custom-components/image-wrapper/ImageWrapper";

interface UserProfileAndMilestoneSectionProps {
  isUser: boolean;
  isAdmin: boolean;
  setCollapsed: Function;
  collapsed: boolean;
  userData: User;
  brand: Brand;
  milestoneProgressData: MilestoneProgressType;
}

const UserProfileAndMilestoneSection: React.FC<
  UserProfileAndMilestoneSectionProps
> = ({
  isAdmin,
  isUser,
  collapsed,
  setCollapsed,
  brand,
  userData,
  milestoneProgressData,
}) => {
  const brandValue = React.useContext(BrandContext);
  const { adminData }: UserState = useSelector(userSelector);
  return (
    <div>
      {(isUser || isAdmin) && (
        <div className="flex justify-end pt-4 pr-7">
          <CaretCircleDoubleLeft
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            className={`${
              collapsed ? "rotate-180" : ""
            } transition-all cursor-pointer  duration-1000 w-7 h-7`}
          />
        </div>
      )}
      {isUser ? (
        // Users

        <div
          className={`flex flex-col transition-[height] duration-500 items-center space-y-2 mt-2 mb-3 ${
            collapsed
              ? "h-20"
              : brandValue.is_tier_enable
              ? "h-[17rem]"
              : "h-[9.5rem]"
          } `}
        >
          <div className="relative flex items-end justify-center rounded-full h- border-3">
            <ImageWrapper
              layout="fill"
              parentClassName={`relative  ${
                collapsed ? "scale-[80%]" : ""
              } transition-all duration-700 w-[4rem] aspect-square`}
              className={`object-cover rounded-full`}
              url={
                userData?.profile_pic_url ?? "/images/profile.placeholder.png"
              }
            />
            {!collapsed && (
              <PencilSimple
                onClick={() => router.push(NextRouteConfig.USER.SETTINGS._root)}
                className="absolute bottom-0 w-4 h-4 cursor-pointer -right-5"
              />
            )}
          </div>
          <Transition
            show={!collapsed}
            as="div"
            className={"flex px-5 w-full flex-col items-center space-y-1"}
            enterFrom="scale-0"
            enter="transition-all duration-500"
            enterTo="scale-110"
            entered="scale-100 duration-300 transition-all"
            leaveFrom="scale-100"
            leaveTo="scale-0"
          >
            <>
              <h1 className={`text-sm font-semibold`}>
                {userData?.username ? (
                  `@${userData?.username}`
                ) : (
                  <span className="text-xs font-semibold opacity-70">N/A</span>
                )}
              </h1>
              <span
                className={`pt-1  text-center ${
                  brandValue.is_tier_enable ? "pb-5" : ""
                } `}
              >
                <h1 className="text-3xl font-semibold">
                  {Utils.numberWithCommas(userData?.points ?? 0)}
                </h1>
                <h1 className="text-xs opacity-50 whitespace-nowrap">
                  Current {brand?.currency ?? "Points"} Balance
                </h1>
              </span>
              {brandValue.is_tier_enable ? (
                <MilestoneProgress
                  id="dashboard-milestone"
                  milestoneProgressData={milestoneProgressData}
                  lifetimePoints={userData?.lifetime_points || 0}
                  showLifetimePoints
                />
              ) : null}
            </>
          </Transition>
        </div>
      ) : isAdmin ? (
        // Admin
        <div
          className={`flex flex-col items-center justify-between mt-6 mb-6 space-y-8`}
        >
          <div
            className={`${
              !!isAdmin ? (collapsed ? "h-20" : "h-36") : ""
            } transition-all duration-500`}
          >
            <ImageWrapper
              layout="fill"
              parentClassName={`relative  ${
                collapsed ? "scale-[55%]" : ""
              } transition-all mx-auto duration-700 w-[6.25rem] aspect-square`}
              className={`object-contain rounded-md`}
              url={brandValue.brand_logo_url}
            />
            {!!isAdmin && (
              <Transition
                show={!collapsed}
                as="div"
                className="!mt-2"
                enterFrom="scale-0"
                enter="transition-all duration-500"
                enterTo="scale-110"
                entered="scale-100 duration-300 transition-all"
                leaveFrom="scale-100"
                leaveTo="scale-0"
              >
                <BrandHeading className="font-light !pb-0 !text-center">
                  Admin
                </BrandHeading>
                <p className="px-5 text-sm font-semibold text-center">
                  @{adminData?.username}
                </p>
                <p className="px-5 text-xs font-light text-center opacity-50">
                  [
                  {adminData?.wallet_address
                    ? Utils.getEllipsisedAddress(adminData?.wallet_address)
                    : Utils.getEllipsisedEmail(adminData?.email!)}
                  ]
                </p>
              </Transition>
            )}
          </div>
        </div>
      ) : (
        // This is for Public Pages
        <div
          className={`flex flex-col items-center justify-between mt-16 mb-6 space-y-8`}
        >
          <div
            className={`${
              collapsed ? "h-20" : "h-36"
            } transition-all duration-500 mx-auto`}
          >
            <ImageWrapper
              layout="fill"
              parentClassName={`relative  ${
                collapsed ? "scale-[55%]" : ""
              } transition-all duration-700 mx-auto w-[6.25rem] aspect-square`}
              className={`object-contain rounded-md`}
              url={brandValue.brand_logo_url}
            />

            <Transition
              show={!collapsed}
              as="div"
              className="!mt-2"
              enterFrom="scale-0"
              enter="transition-all duration-500"
              enterTo="scale-110"
              entered="scale-100 duration-300 transition-all"
              leaveFrom="scale-100"
              leaveTo="scale-0"
            >
              <BrandHeading className="!pb-0 !text-center">
                {brand?.title}
              </BrandHeading>
            </Transition>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(UserProfileAndMilestoneSection);
