import {
  default as Axios,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  CancelToken,
  CancelTokenSource,
  default as axios,
} from "axios";
import { ENV_BE_BASE_URL, ENV_FE_BASE_URL } from "../../constants/secret";
import { webStorageService } from "../WebStorageService";
import { webToastService } from "../WebToastService";

interface RequestConfig extends AxiosRequestConfig {
  requestId?: string;
  redirectIfUnauthorized?: boolean;
}

export declare type QueryParams = {
  [key: string]: string | string[] | undefined;
};

const BASE_URL = ENV_BE_BASE_URL;

export class BaseApiService {
  private static instance: BaseApiService;

  private requestMap = new Map<string, CancelTokenSource>();

  public static getInstance(): BaseApiService {
    if (!this.instance) {
      this.instance = new BaseApiService();
    }
    return this.instance;
  }

  public get<T = any>(
    url: string,
    opts?: {
      params?: QueryParams;
      headers?: AxiosRequestHeaders;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
      };
    }
  ): Promise<T> {
    return this.request<T>(
      {
        method: "GET",
        url,
        headers: opts?.headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth
    );
  }

  public delete<T = any>(
    url: string,
    opts?: {
      params?: QueryParams;
      headers?: AxiosRequestHeaders;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
      };
    }
  ) {
    return this.request<T>(
      {
        method: "DELETE",
        url,
        headers: opts?.headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth
    );
  }

  public post<T = any>(
    url: string,
    data?: any,
    opts?: {
      headers?: AxiosRequestHeaders;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
      };
    }
  ) {
    return this.request<T>(
      {
        method: "POST",
        url,
        data,
        headers: opts?.headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth
    );
  }

  public put<T = any>(
    url: string,
    data?: any,
    opts?: {
      headers?: AxiosRequestHeaders;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
      };
    }
  ) {
    return this.request<T>(
      {
        method: "PUT",
        url,
        data,
        headers: opts?.headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth
    );
  }

  public patch<T = any>(
    url: string,
    data?: any,
    opts?: {
      headers?: AxiosRequestHeaders;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth: boolean;
      };
    }
  ) {
    return this.request<T>(
      {
        method: "PATCH",
        url,
        data,
        headers: opts?.headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth
    );
  }

  public async uploadFileToS3(file: File, presignedUrl: string) {
    return await axios.put(presignedUrl, file, {
      headers: {
        "Content-Disposition": `attachment`,
        "Content-Type": `${file.type}`,
      },
    });
  }

  public async uploadFileToS3UsingObjectURL(
    objectUrl: string,
    presignedUrl: string
  ) {
    // Fetch the file content from the objectUrl
    const blobObj = await fetch(objectUrl);
    const fileBlob = await blobObj.blob();

    // Create a File object from the Blob
    const file = new File([fileBlob], "file");

    const response = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Disposition": `attachment`,
        "Content-Type": `${file.type}`,
      },
    });
    await URL.revokeObjectURL(objectUrl);
    return response;
  }

  generateHeaders = async (
    headers?: AxiosRequestHeaders,
    useAuth?: boolean
  ) => {
    let defaultHeaders: any = {
      origin: ENV_FE_BASE_URL,
    };

    if (useAuth) {
      const token = await webStorageService.getAuthToken();
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `bearer ${token}`,
      };
    }

    if (!headers) {
      return defaultHeaders;
    }
    return { ...defaultHeaders, ...headers };
  };

  private async request<T>(
    config: RequestConfig,
    useAuth?: boolean
  ): Promise<T> {
    const cancelToken = this.addToRequestMap(config.requestId);
    try {
      const response = await Axios.request({
        baseURL: BASE_URL,
        cancelToken,
        ...config,
        headers: await this.generateHeaders(config.headers, useAuth ?? true),
      });
      this.removeFromRequestMap(config.requestId);
      return response?.data as T;
    } catch (error: any) {
      if (![429, "40", "44"].includes(error.response?.data?.code)) {
        if (error.response?.data?.code === "21") {
          webToastService.showError(
            "You can only add one Quest for the selected category.",
            { autoClose: 3500 }
          );
        } else {
          webToastService.showError(
            error.response?.data?.message?.split(": ")[1]
          );
        }
      }
      if (error.response && error.response.data) {
        if (
          error.response.data.status === 500 ||
          error.response.data.status === 401
        ) {
          await webStorageService.reset();
        }
        console.log(error.response.data);
      } else {
        console.log(error);
      }
      throw error;
    }
  }

  private addToRequestMap(requestId?: string): CancelToken | undefined {
    if (!requestId) {
      return undefined;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }
}

export const baseApiService = BaseApiService.getInstance();
