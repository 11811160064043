import { InstagramLogo, TiktokLogo } from "phosphor-react";
import TwitterXLogo from "../../public/icons/TwitterXLogo";
import CustomDiscordLogo from "../../public/icons/custom-discord-logo";
import {
  BrandImageType,
  CampaignsSortBy,
  EngagementType,
  ExclusiveContentFilterBy,
  FilterBy,
  ProofSubType,
  Providers,
  QuestCategory,
  QuestFilterBy,
  QuestLear2EarnSlug,
  QuestMenuActions,
  QuestPollSlug,
  QuestProofSlug,
  QuestQuizSlug,
  QuestSurveysSlug,
  QuizQuestCategory,
  SortBy,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
  UserQuestStatus,
} from "../utils/enums";

export const brandBackgroundColor = [
  { name: "Black", code: "#000000" },
  { name: "White", code: "#FFFFFF" },
];

export enum ToastContainerIds {
  APP_INSTALL_PROMPT_TOAST = "app_install_prompt_toast",
  PUSH_NOTIFICATIONS_PROMPT_TOAST = "push_notifications_prompt_toast",
}

export const brandUpdateFields = [
  {
    name: "title",
    label: "Company Name",
    placeholder: "Name",
  },
  {
    name: "website_url",
    label: "Company Website",
    placeholder: "Add Url",
  },
  {
    name: "currency",
    label: "Currency",
    placeholder: "Add Currency",
  },
  {
    name: "privacy_policy_url",
    label: "Privacy Policy",
    placeholder: "Add Link",
  },
  {
    name: "terms_and_condition_url",
    label: "Terms and conditions",
    placeholder: "Add Link",
  },
  {
    name: "discord_url",
    label: "Discord",
    placeholder: "Add Url",
  },
  {
    name: "instagram_url",
    label: "Instagram",
    placeholder: "Add Url",
  },
  {
    name: "tiktok_url",
    label: "Tik Tok",
    placeholder: "Add Url",
  },
  {
    name: "youtube_url",
    label: "Youtube",
    placeholder: "Add Url",
  },
  // {
  //     name: 'twitter_url',
  //     label: 'Twitter',
  //     placeholder: 'Add Url'
  // },
];

export const adminFilters = [
  {
    text: "Live",
    filterBy: FilterBy.live,
  },
  {
    text: "Archived",
    filterBy: FilterBy.archived,
  },
  {
    text: "Draft",
    filterBy: FilterBy.DRAFT,
  },
  {
    text: "Sold Out",
    filterBy: FilterBy.sold_out,
  },
  {
    text: "Coming Soon",
    filterBy: FilterBy.coming_soon,
  },
];

export const showClaimsQuestFilters = [
  {
    text: "Claimed",
    filterBy: UserQuestStatus.CLAIMED,
  },
  {
    text: "Pending",
    filterBy: UserQuestStatus.PENDING,
  },
  {
    text: "Rejected",
    filterBy: UserQuestStatus.REJECTED,
  },
];

export const questFilters = [
  {
    text: "Live",
    filterBy: QuestFilterBy.live,
  },
  {
    text: "Archived",
    filterBy: QuestFilterBy.archived,
  },
  {
    text: "Draft",
    filterBy: QuestFilterBy.draft,
  },
  {
    text: "exhausted",
    filterBy: QuestFilterBy.exhausted,
  },
  {
    text: "Coming Soon",
    filterBy: QuestFilterBy.coming_soon,
  },
  {
    text: "Ended",
    filterBy: QuestFilterBy.past,
  },
];

export const userQuestFilters = [
  {
    text: "Live",
    filterBy: QuestFilterBy.live,
  },

  {
    text: "exhausted",
    filterBy: QuestFilterBy.exhausted,
  },
];

export const myItemsFilters = [
  {
    text: "Discount",
    filterBy: "Discount",
  },
  {
    text: "Allowlist",
    filterBy: "Allowlist",
  },
  {
    text: "Art",
    filterBy: "Art",
  },
  {
    text: "Merchandise",
    filterBy: "Merchandise",
  },
  {
    text: "NFT",
    filterBy: "NFT",
  },
  // {
  //   text: "Comics",
  //   filterBy: "Comics",
  // },
  {
    text: "Experience",
    filterBy: "Experience",
  },
  // {
  //   text: "NFH IQ",
  //   filterBy: "NFH IQ",
  // },
  {
    text: "POAP",
    filterBy: "POAP",
  },
  {
    text: "Raffle",
    filterBy: "Raffle",
  },
  // {
  //   text: "The Frontier",
  //   filterBy: "The Frontier",
  // },
  {
    text: "Service",
    filterBy: "Service",
  },
];
export const primaryFonts = [
  {
    text: "Abril Fatface",
    name: "Abril Fatface",
  },
  {
    text: "Bebas Neue",
    name: "Bebas Neue",
  },
  {
    text: "Biennale",
    name: "Biennale",
  },
  {
    text: "Blaster",
    name: "Blaster",
  },
  {
    text: "Changa One",
    name: "Changa One",
  },
  {
    text: "Droid Sans",
    name: "Droid Sans",
  },
  {
    text: "Exo",
    name: "Exo",
  },
  {
    text: "Great Vibes",
    name: "Great Vibes",
  },
  {
    text: "Helvetica",
    name: "Helvetica",
  },
  {
    text: "Inconsolata",
    name: "Inconsolata",
  },
  {
    text: "Inter",
    name: "Inter",
  },
  {
    text: "Josefin Sans",
    name: "Josefin Sans",
  },
  {
    text: "Lato",
    name: "Lato",
  },
  {
    text: "League Spartan",
    name: "League Spartan",
  },
  {
    text: "Libre Baskerville",
    name: "Libre Baskerville",
  },
  {
    text: "Merriweather",
    name: "Merriweather",
  },
  {
    text: "Montserrat",
    name: "Montserrat",
  },
  {
    text: "Nunito",
    name: "Nunito",
  },
  {
    text: "Oswald",
    name: "Oswald",
  },
  {
    text: "Pacifico",
    name: "Pacifico",
  },
  {
    text: "Poppins",
    name: "Poppins",
  },
  {
    text: "PT Sans",
    name: "PT Sans",
  },
  {
    text: "PT Serif",
    name: "PT Serif",
  },
  {
    text: "Raleway",
    name: "Raleway",
  },
  {
    text: "Rethink Sans",
    name: "Rethink Sans",
  },
  {
    text: "Roboto",
    name: "Roboto",
  },
  {
    text: "Sacramento",
    name: "Sacramento",
  },
  {
    text: "Saira",
    name: "Saira",
  },
  // {
  //   text: "Slabo",
  //   name: "Slabo 13px",
  // },
  {
    text: "Source Sans Pro",
    name: "Source Sans Pro",
  },
  {
    text: "Thyga",
    name: "Thyga",
  },
  {
    text: "Thyga Italisised",
    name: "Thyga_Italic",
  },
  {
    text: "Ubuntu",
    name: "Ubuntu",
  },
  {
    text: "Varela",
    name: "Varela",
  },
  {
    text: "Varela Round",
    name: "Varela Round",
  },
  {
    text: "Vollkorn",
    name: "Vollkorn",
  },
  {
    text: "KumbhSans",
    name: "KumbhSans",
  },
  {
    text: "Fredoka",
    name: "Fredoka",
  },
];

export const activities = [
  {
    title: "Quests",
    icon: (className?: string) => <TwitterXLogo className={className} />,
    provider: Providers.QUEST,
  },
  {
    title: `X (Twitter)`,
    icon: (className?: string) => <TwitterXLogo className={className} />,
    provider: Providers.TWITTER,
  },
  {
    title: `Discord`,
    icon: (className?: string) => <CustomDiscordLogo className={className} />,
    provider: Providers.DISCORD,
  },
  // {
  //   title: `Youtube`,
  //   icon: (className?: string) => <YoutubeLogo className={className} />,
  //   provider: Providers.YOUTUBE,
  // },
  // {
  //   title: `Spotify`,
  //   icon: (className?: string) => <SpotifyLogo className={className} />,
  //   provider: Providers.SPOTIFY,
  // },
  // {
  //   title: `LinkedIn`,
  //   icon: (className?: string) => <LinkedinLogo className={className} />,
  //   provider: Providers.LINKEDIN,
  // },
  {
    title: `Instagram`,
    icon: (className?: string) => <InstagramLogo className={className} />,
    provider: Providers.INSTAGRAM,
  },
  {
    title: `TikTok`,
    icon: (className?: string) => <TiktokLogo className={className} />,
    provider: Providers.TIKTOK,
  },
  // {
  //     title: `Gmail Activities`,
  //     provider: Providers.GMAIL
  // },
  // {
  // 	title: `More Activities`,
  // 	provider: `other`,
  // },
];

export const sortMenu = [
  { text: "Recently Updated", sortBy: SortBy.updated_at },
  { text: "Price - Low to High", sortBy: SortBy.value_asc },
  { text: "Price - High to Low", sortBy: SortBy.value_desc },
];

export const ExclusiveShortMenu = [
  { text: "Recently Updated", sortBy: SortBy.updated_at },
  { text: "A - Z", sortBy: SortBy.title_asc },
  { text: "Z - A", sortBy: SortBy.title_desc },
  { text: "Oldest Updated", sortBy: SortBy.oldest_updated },
];

export const campaignsSortMenu = [
  { text: "Recently Updated", sortBy: CampaignsSortBy.updated_at },
  { text: "Claimed - Low to High", sortBy: CampaignsSortBy.value_asc },
  { text: "Claimed - High to Low", sortBy: CampaignsSortBy.value_desc },
];

export const userFilters = [
  {
    text: "Live",
    filterBy: FilterBy.live,
  },
  {
    text: "Sold Out",
    filterBy: FilterBy.sold_out,
  },
];

export const engagementTableHeadings = [
  "",
  "Engagement",
  "Account/Provider ID",
  "Date",
  "Link",
  "Amt",
  <span key={"max_allowed"}>
    Max Allowed<sup>#</sup>
  </span>,
  <span key={"claimed"}>
    Claimed<sup>#</sup>
  </span>,
  "Interaction",
  "Status",
  "Actions",
];
export const itemStatsMetaTableHeadings = [
  "",
  "Tx ID",
  "Quantity",
  "Amount",
  "Email/Discord",
  "Purchase Time",
];
export const itemStatsTableHeadings = [
  "",
  "User",
  "Total Quantity",
  "Last Purchase Time",
  "Action",
];

export const brandImageSetting = [
  {
    label: "Your Logo*",
    meta: {
      dimension: "500 x 500",
    },
    placeholderImage: "",
    type: BrandImageType.LOGO,
    accept: ".png, .jpeg, .jpg",
  },
  {
    label: "Banner Image*",
    meta: {
      dimension: "1200 x 100",
      text: "Click to upload",
    },
    placeholderImage: "",
    type: BrandImageType.BANNER,
    accept: ".png, .jpeg, .jpg",
  },
  {
    label: "Share Image",
    meta: {
      dimension: "1600 x 900",
    },
    placeholderImage: "",
    type: BrandImageType.SHARE_IMAGE,
    accept: ".png, .jpeg, .jpg",
  },
  {
    label: "Favicon",
    meta: {
      dimension: "48 x 48",
    },
    placeholderImage: "",
    type: BrandImageType.FAVICON,
    accept: ".ico",
  },
];

export const engagementActions = ["edit", "duplicate", "pause"];

export const ExclusiveContentActionsMap = [
  "edit",
  "duplicate",
  "Remove",
  "Archived",
  "Published",
  "Draft",
];
export const socialLoginTimeOut = 300000; //in ms
export const questCategory = [
  {
    label: "Learn2Earn",
    shortLabel: "L2E",
    category: QuestCategory.LEARN_2_EARN,
  },
  {
    label: "Quiz",
    shortLabel: "Quiz",
    category: QuestCategory.QUIZ,
  },
  {
    label: "Poll",
    shortLabel: "Poll",
    category: QuestCategory.POLL,
  },
  {
    label: "Survey",
    shortLabel: "Survey",
    category: QuestCategory.SURVEY,
  },
  {
    label: "Refer A Friend",
    shortLabel: "Refer",
    category: QuestCategory.REFER_2_EARN,
  },
  {
    label: "Proof Needed",
    shortLabel: "Proof",
    category: QuestCategory.PROOF,
  },
  // {
  //   label: "Suggestion Box",
  //   shortLabel: "Suggest"
  //   category: QuestCategory.SUGGESTION_BOX,
  // },
  // {
  //   label: "Create Your Own",
  //   category: QuestCategory.CUSTOM,
  // },
];

export const questCategorySlugs = {
  [QuestCategory.LEARN_2_EARN]: [QuestLear2EarnSlug.VIDEO_URL],
  [QuestCategory.QUIZ]: [QuestQuizSlug.QUESTIONS],
  [QuestCategory.POLL]: [QuestPollSlug.POLL_QUESTIONS],
  [QuestCategory.SURVEY]: [QuestSurveysSlug.QUESTIONS],
  [QuestCategory.PROOF]: [QuestProofSlug.PROOF],
};

export const quizQuestCategory = [
  {
    label: "Multiple choice",
    category: QuizQuestCategory.MULTIPLE_CHOICE,
  },
  {
    label: "Single choice",
    category: QuizQuestCategory.SINGLE_CHOICE,
  },
];

export const proofQuestTypes = [
  { value: ProofSubType.MEDIA, label: "Upload Image or Video" },
  { value: ProofSubType.TEXT, label: "Input Text" },
];

export const questMenuActions = [
  {
    label: "Start Quest",
    value: QuestMenuActions.START,
  },
  // {
  //   label: "More Info",
  //   value: QuestMenuActions.INFO,
  // },
  // {
  //   label: "Hide",
  //   value: QuestMenuActions.HIDE,
  // },
  // {
  //   label: "Skip",
  //   value: QuestMenuActions.SKIP,
  // },
];

export const AdminExclusiveContentFilters = [
  {
    text: "Live",
    filterBy: ExclusiveContentFilterBy.LIVE,
  },
  {
    text: "Archived",
    filterBy: ExclusiveContentFilterBy.ARCHIVED,
  },
  {
    text: "Draft",
    filterBy: ExclusiveContentFilterBy.DRAFT,
  },
  {
    text: "Coming Soon",
    filterBy: ExclusiveContentFilterBy.COMING_SOON,
  },
  {
    text: "Past",
    filterBy: ExclusiveContentFilterBy.PAST,
  },
];

export const popoverCartElement = [
  EngagementType.JOIN,
  EngagementType.FOLLOW,
  EngagementType.SUBSCRIBE,
];

export const adminTransactionsSortMenu = [
  { text: "Oldest", sortBy: SortBy.updated_at_asc },
  { text: "Amount: High to Low", sortBy: SortBy.amount_desc },
  { text: "Amount: Low to High", sortBy: SortBy.amount_asc },
];

export const typeFilterMenu = [
  {
    text: "Credit",
    filterBy: TransactionType.CREDIT,
  },
  {
    text: "Debit",
    filterBy: TransactionType.DEBIT,
  },
];

export const categoryFilterMenu = [
  {
    text: "Revoke",
    filterBy: TransactionCategory.REVOKE,
  },
  {
    text: "Gift",
    filterBy: TransactionCategory.GIFT,
  },
  {
    text: "Item Purchase",
    filterBy: TransactionCategory.ITEM_PURCHASE,
  },
  {
    text: "Quests",
    filterBy: TransactionCategory.QUEST,
  },
  {
    text: "Engagement",
    filterBy: TransactionCategory.ENGAGEMENT,
  },
  {
    text: "Social Login",
    filterBy: TransactionCategory.SOCIAL_LOGIN,
  },
  {
    text: "Welcome",
    filterBy: TransactionCategory.WELCOME,
  },
  {
    text: "Revert",
    filterBy: TransactionCategory.REVERT,
  },
];

export const statusFilterMenu = [
  {
    text: "Completed",
    filterBy: TransactionStatus.COMPLETED,
  },
  {
    text: "Pending",
    filterBy: TransactionStatus.PENDING,
  },
  {
    text: "Failed",
    filterBy: TransactionStatus.FAILED,
  },
];

export const revertTransactionCategoryDescription: Record<string, string[]> = {
  [TransactionCategory.ITEM_PURCHASE]: [
    "User will no longer have the items in their account",
    "Points will be credited back to the user",
    "Item inventory will be updated",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.ENGAGEMENT]: [
    "Points will be debited from the user",
    "No. of engagement claims will be updated",
    "Related engagement will be removed from user account",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.SOCIAL_LOGIN]: [
    "Points will be debited from the user",
    "Related engagement will be removed from user account",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.WELCOME]: [
    "Points will be debited from user",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.GIFT]: [
    "Points will be debited from user",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.QUEST]: [
    "Points will be debited from user",
    "Quest inventory will be updated",
    "Quest status will be updated to Rejected state",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
  [TransactionCategory.REVOKE]: [
    "Points will be credited back to user",
    "A new revert transaction will be created corresponding to the original transaction",
  ],
};
