// Auth
export const AUTH_LOGIN_HALF = "auth/login/half";
export const AUTH_LOGIN_HALF_COMPLETED = "auth/login/half/completed";
export const AUTH_LOGIN_HALF_EMAIL_SENT = "auth/login/half/email/sent";
export const AUTH_LOGIN_HALF_ERROR = "auth/login/half/error";

export const AUTH_LOGIN_HALF_DISABLED_UI = "auth/login/half/disabled/ui";
export const AUTH_LOGIN_SHOW_MODAL_UI = "auth/login/show/modal/ui";

export const AUTH_VERIFY_EMAIL = "auth/verify-email";
export const AUTH_VERIFY_EMAIL_COMPLETED = "auth/verify-email/completed";
export const AUTH_VERIFY_EMAIL_ERROR = "auth/verify-email/error";

export const AUTH_CHECK_VERIFICATION = "auth/check-verification";
export const AUTH_CHECK_VERIFICATION_COMPLETED =
  "auth/check-verification/completed";
export const AUTH_CHECK_VERIFICATION_ERROR = "auth/check-verification/error";

export const AUTH_SIGNATURE_REJECTED = "auth/signature/rejected";

export const AUTH_VERIFY_SIGNATURE = "auth/verify-signature";
export const AUTH_LOGIN_COMPLETED = "auth/login/completed";
export const AUTH_VERIFY_SIGNATURE_ERROR = "auth/verify-signature/error";

export const AUTH_LOGIN_EMAIL_RESEND = "auth/login/email/resend";
export const AUTH_LOGIN_EMAIL_RESEND_COMPLETED =
  "auth/login/email/resend/comeplted";
export const AUTH_LOGIN_EMAIL_RESEND_ERROR = "auth/login/email/resend/error";
export const AUTH_LOGIN_EMAIL_RESEND_RESET = "auth/login/email/resend/reset";

export const AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN =
  "auth/login/wallet/address/reject/sign";
export const AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_COMPLETED =
  "auth/login/wallet/address/reject/sign/completed";
export const AUTH_LOGIN_WALLET_ADDRESS_REJECT_SIGN_ERROR =
  "auth/login/wallet/address/reject/sign/error";

// email marketing modal
export const SHOW_EMAIL_MARKETING_MODAL = "show/email/marketing/modal";

export const USER_DISCONNECT = "user/disconnect";

export const USER_TRANSFER_LORE = "user/transfer-lore";
export const USER_TRANSFER_LORE_COMPLETED = "user/transfer-lore/completed";
export const USER_TRANSFER_LORE_ERROR = "user/transfer-lore/error";

export const USER_FETCH_ME = "user/fetch/me";
export const USER_FETCH_ME_COMPLETED = "user/fetch/me/completed";
export const USER_FETCH_ME_ERROR = "user/fetch/me/error";

export const NFT_EMPTY = "nft/empty";

export const NFT_CHECK_LORE_VALUE = "nft/check-lore-value";
export const NFT_CHECK_LORE_VALUE_COMPLETED = "nft/check-lore-value/completed";
export const NFT_CHECK_LORE_VALUE_ERROR = "nft/check-lore-value/error";

export const NFT_LIST_OWNED_REDEEMABLE = "nft/list";
export const NFT_LIST_ALL_OWNED_REDEEMABLE = "nft/list/all";
export const NFT_LIST_OWNED_REDEEMABLE_COMPLETED = "nft/list/completed";
export const NFT_LIST_ALL_OWNED_REDEEMABLE_COMPLETED = "nft/list/all/completed";
export const NFT_LIST_OWNED_REDEEMABLE_ERROR = "nft/list/error";
export const NFT_LIST_ALL_OWNED_REDEEMABLE_ERROR = "nft/list/all/error";

export const NFT_CLAIM_LORE_VALUE = "nft/claim-lore-value";
export const NFT_CLAIM_LORE_VALUE_COMPLETED = "nft/claim-lore-value/completed";
export const NFT_CLAIM_LORE_VALUE_ERROR = "nft/claim-lore-value/error";

export const NFT_CLAIM_ALL_LORE_VALUE = "nft/claim-lore-value/all";
export const NFT_CLAIM_ALL_LORE_VALUE_COMPLETED =
  "nft/claim-lore-value/all/completed";
export const NFT_CLAIM_ALL_LORE_VALUE_ERROR = "nft/claim-lore-value/all/error";

export const ITEM_GET = "items/id";
export const ITEM_GET_COMPLETED = "items/id/completed";
export const ITEM_GET_ERROR = "items/id/error";
export const ITEM_CREATE = "items/create";
export const ITEM_CREATE_COMPLETED = "items/create/completed";
export const ITEM_CREATE_ERROR = "items/create/error";
export const ITEM_IMAGE_EDIT = "items/image/id/edit";
export const ITEM_IMAGE_EDIT_COMPLETED = "items/image/id/edit/completed";
export const ITEM_IMAGE_EDIT_ERROR = "items/image/id/edit/error";
export const ITEM_EDIT = "items/id/edit";
export const ITEM_EDIT_COMPLETED = "items/id/edit/completed";
export const ITEM_EDIT_ERROR = "items/id/edit/error";
export const ITEM_LIST_GET = "items/page";
export const ITEM_LIST_GET_COMPLETED = "items/page/completed";
export const ITEM_LIST_GET_ERROR = "items/page/error";
export const ITEM_LIST_RESET = "items/reset";
export const ITEM_SET_CURRENT_ELEMENT = "items/currentElement";
export const ITEM_LIST_OWNED_GET = "items/owned/page";
export const ITEM_LIST_OWNED_GET_COMPLETED = "items/owned/page/completed";
export const ITEM_LIST_OWNED_GET_ERROR = "items/owned/page/error";
export const ITEM_SET_OWNED_CURRENT_ELEMENT = "items/owned/currentElement";
export const ITEM_LIST_OWNED_RESET = "items/owned/reset";
export const ITEM_REDEEM = "items/id/redeem";
export const ITEM_REDEEM_COMPLETED = "items/id/redeem/completed";
export const ITEM_REDEEM_ERROR = "items/id/redeem/error";

export const ITEM_PICK_WINNER = "items/pick winner";
export const ITEM_PICK_WINNER_COMPLETED = "items/pick winner/completed";
export const ITEM_PICK_WINNER_ERROR = "items/pick winner/error";

export const TRANSACTION_LIST = "transaction/list";
export const TRANSACTION_LIST_COMPLETED = "transaction/list/completed";
export const TRANSACTION_LIST_ERROR = "transaction/list/error";

export const USER_TRANSACTION_LIST_GET = "user/transactions/list/get";
export const USER_TRANSACTION_LIST_GET_COMPLETED =
  "user/transactions/list/get/completed";
export const USER_TRANSACTION_LIST_GET_ERROR =
  "user/transactions/list/get/error";

export const TRANSACTION_LIST_EMPTY = "transaction/list/empty";
export const USER_TRANSACTION_LIST_EMPTY = "user/transaction/list/empty";

export const TRANSACTION_REVERT = "transaction/revert";
export const TRANSACTION_REVERT_COMPLETED = "transaction/revert/completed";
export const TRANSACTION_REVERT_ERROR = "transaction/revert/error";

export const ITEM_STATS_LIST = "items-stats/id/list";
export const ITEM_STATS_LIST_COMPLETED = "items-stats/id/list/completed";
export const ITEM_STATS_LIST_ERROR = "items-stats/id/list/error";

export const ITEM_STATS_DOWNLOAD = "items-stats/id/download";
export const ITEM_STATS_DOWNLOAD_COMPLETED =
  "items-stats/id/download/completed";

export const TRANSFER = "transfer";
export const TRANSFER_COMPLETED = "transfer/completed";
export const TRANSFER_ERROR = "transfer/error";

export const SOCIAL_LOGIN = "user/social/login";

export const ITEM_REPORT_DOWNLOAD = "item/reports/download";
export const ITEM_REPORT_DOWNLOAD_COMPLETED = "item/reports/download/completed";
export const ITEM_REPORT_DOWNLOAD_ERROR = "item/reports/download/error";

export const ITEM_REDEEM_RESET = "item/redeem/reset";

export const ENGAGEMENT_LIST_GET = "engagement/list/get";
export const ENGAGEMENT_LIST_GET_COMPLETED =
  "engagement/completed/list/get/completed";
export const ENGAGEMENT_LIST_GET_ERROR = "engagement/list/get/error";

export const USER_ENGAGEMENT_LIST_GET = "user/engagement/list/get";
export const USER_ENGAGEMENT_LIST_GET_COMPLETED =
  "user/engagement/list/get/completed";
export const USER_ENGAGEMENT_LIST_GET_ERROR = "user/engagement/list/get/error";

export const USER_ENGAGEMENTS_CLAIM = "user/engagements/claim";
export const USER_ENGAGEMENTS_CLAIM_COMPLETED =
  "user/engagements/claim/completed";
export const USER_ENGAGEMENTS_CLAIM_ERROR = "user/engagements/claim/error";

export const USER_ENGAGEMENTS_AMOUNT_RESET = "user/engagements/amount/reset";

export const ENGAGEMENT_CREATE = "engagement/create";
export const ENGAGEMENT_CREATE_COMPLETED = "engagement/create/completed";
export const ENGAGEMENT_CREATE_ERROR = "engagement/create/error";

export const ENGAGEMENT_UPDATE = "engagement/update";
export const ENGAGEMENT_UPDATE_COMPLETED = "engagement/update/completed";
export const ENGAGEMENT_UPDATE_ERROR = "engagement/update/error";

export const BRAND_UPDATE = "brand/update";
export const BRAND_UPDATE_COMPLETED = "brand/update/completed";
export const BRAND_UPDATE_ERROR = "brand/update/error";

export const BRAND_CONFIG_BULK_UPDATE = "brand/config/bulk/update";
export const BRAND_CONFIG_BULK_UPDATE_COMPLETED =
  "brand/config/bulk/update/completed";
export const BRAND_CONFIG_BULK_UPDATE_ERROR = "brand/config/bulk/update/error";

export const BRAND_GET = "get/brand";
export const BRAND_GET_COMPLETED = "get/brand/completed";
export const BRAND_GET_ERROR = "get/brand/error";

export const BRAND_LOGO_UPDATE = "brand/logo/update";
export const BRAND_LOGO_UPDATE_COMPLETED = "brand/logo/update/completed";
export const BRAND_LOGO_UPDATE_ERROR = "brand/logo/update/error";

export const BRAND_SHARE_IMAGE_UPDATE = "brand/share/image/update";
export const BRAND_SHARE_IMAGE_UPDATE_COMPLETED =
  "brand/share/image/update/completed";
export const BRAND_SHARE_IMAGE_UPDATE_ERROR = "brand/share/image/update/error";

export const BRAND_FAVICON_UPDATE = "brand/favicon/update";
export const BRAND_FAVICON_UPDATE_COMPLETED = "brand/favicon/update/completed";
export const BRAND_FAVICON_UPDATE_ERROR = "brand/favicon/update/error";

export const BRAND_BANNER_UPDATE = "brand/banner/update";
export const BRAND_BANNER_UPDATE_COMPLETED = "brand/banner/update/completed";
export const BRAND_BANNER_UPDATE_ERROR = "brand/banner/update/error";

export const USER_PROFILE_IMAGE_UPDATE = "user/profile/image/update";
export const USER_PROFILE_IMAGE_UPDATE_COMPLETED =
  "user/profile/image/update/completed";
export const USER_PROFILE_IMAGE_UPDATE_ERROR =
  "user/profile/image/update/error";

export const USER_PROFILE_UPDATE = "user/profile/update";
export const USER_PROFILE_UPDATE_COMPLETED = "user/profile/update/completed";
export const USER_PROFILE_UPDATE_ERROR = "user/profile/update/error";

export const ADD_ADMIN_WALLET_ADDRESS = "admin/wallet/address";
export const ADD_ADMIN_WALLET_ADDRESS_COMPLETED =
  "admin/wallet/address/completed";
export const ADD_ADMIN_WALLET_ADDRESS_ERROR = "admin/wallet/address/error";

export const EDIT_ADMIN = "edit/admin/";
export const EDIT_ADMIN_COMPLETED = "edit/admin/completed";
export const EDIT_ADMIN_ERROR = "edit/admin/error";

export const USER_DEAUTHORIZE = "user/deauthorize";
export const USER_DEAUTHORIZE_COMPLETED = "user/deauthorize/completed";
export const USER_DEAUTHORIZE_ERROR = "user/deauthorize/error";

export const ENGAGEMENT_TOGGLE_ARCHIVE = "engagement/toggle/archive";
export const ENGAGEMENT_TOGGLE_ARCHIVE_COMPLETED =
  "engagements/toggle/archive/completed";
export const ENGAGEMENT_TOGGLE_ARCHIVE_ERROR =
  "engagements/toggle/archive/error";

export const USERS_LIST_GET = "users/list/get";
export const USERS_LIST_GET_COMPLETED = "users/list/get/completed";
export const USERS_LIST_GET_ERROR = "users/list/get/error";

export const USER_LIST_RESET = "user/list/reset";

export const USER_AWARD_POINTS = "user/award/points";
export const USER_AWARD_POINTS_COMPLETED = "user/award/points/completed";
export const USER_AWARD_POINTS_ERROR = "user/award/points/error";
export const USER_REVOKE_POINTS = "user/revoke/points";
export const USER_REVOKE_POINTS_COMPLETED = "user/revoke/points/completed";
export const USER_REVOKE_POINTS_ERROR = "user/revoke/points/error";

export const USER_GET = "user/get";
export const USER_GET_COMPLETED = "user/get/completed";
export const USER_GET_ERROR = "user/get/error";

export const USER_LEADERBOARD_LIST_GET = "user/leaderboard/list/get";
export const USER_LEADERBOARD_LIST_GET_ERROR =
  "user/leaderboard/list/get/error";
export const USER_LEADERBOARD_LIST_GET_COMPLETED =
  "user/leaderboard/list/get/completed";

export const ADMIN_ANALYTICS_STATS_GET = "admin/analytics/stats/get";
export const ADMIN_ANALYTICS_STATS_GET_ERROR =
  "admin/analytics/stats/get/error";
export const ADMIN_ANALYTICS_STATS_GET_COMPLETED =
  "admin/analytics/stats/get/completed";

export const BRAND_SOCIALS_CREDENTIALS_GET =
  "admin/brand/social-credentials/get";
export const BRAND_SOCIALS_CREDENTIALS_GET_COMPLETED =
  "admin/brand/social-credentials/get/completed";
export const BRAND_SOCIALS_CREDENTIALS_GET_ERROR =
  "admin/brand/social-credentials/get/error";

export const BRAND_SOCIALS_CREDENTIALS_UPDATE =
  "admin/brand/social-credentials/update";
export const BRAND_SOCIALS_CREDENTIALS_UPDATE_COMPLETED =
  "admin/brand/social-credentials/update/completed";
export const BRAND_SOCIALS_CREDENTIALS_UPDATE_ERROR =
  "admin/brand/social-credentials/update/error";

export const BRAND_PWA_APPEARANCE_CONFIG_ACTION =
  "admin/brand/pwa/appearance/config/action";

export const USER_UPDATE = "user/username/profile-pic/update";
export const USER_UPDATE_COMPLETED =
  "user/username/profile-pic/update/completed";
export const USER_UPDATE_ERROR = "user/username/profile-pic/update/error";

export const ADMIN_LIST_GET = "admin/list/get";
export const ADMIN_LIST_GET_COMPLETED = "admin/list/get/completed";
export const ADMIN_LIST_GET_ERROR = "admin/list/get/error";

export const ADMIN_LIST_RESET = "admin/list/reset";

export const ADMIN_WALLET_ADDRESS_DELETE = "admin/wallet/address/delete";
export const ADMIN_WALLET_ADDRESS_DELETE_COMPLETED =
  "admin/wallet/address/delete/completed";
export const ADMIN_WALLET_ADDRESS_DELETE_ERROR =
  "admin/wallet/address/delete/error";

export const BRAND_LATEST_TWEET_LIST_GET = "brans/latest-tweet/list/get";
export const BRAND_LATEST_TWEET_LIST_GET_COMPLETED =
  "brans/latest-tweet/list/get/completed";
export const BRAND_LATEST_TWEET_LIST_GET_ERROR =
  "brans/latest-tweet/list/get/error";

export const USER_TWITTER_PROFILE_CONFIRMATION_MODAL =
  "user/twitter/profile/confirmation/modal";
export const USER_TWITTER_PROFILE_CONFIRMATION_MODAL_CANCEL =
  "user/twitter/profile/confirmation/modal/cancel";

export const USER_SOCIAL_CONNECT = "user/social/connect";
export const USER_SOCIAL_CONNECT__CANCELED = "user/social/connect/canceled";

export const BRAND_CONFIG_UPDATE = "brand/config/update";
export const BRAND_CONFIG_UPDATE_COMPLETED = "brand/config/update/completed";
export const BRAND_CONFIG_UPDATE_ERROR = "brand/config/update/error";

export const MY_ACTIVITY_LIST_GET = "user/my-activity/list/get";
export const MY_ACTIVITY_LIST_GET_COMPLETED =
  "user/my-activity/list/get/completed";
export const MY_ACTIVITY_LIST_GET_ERROR = "user/my-activity/list/get/error";

export const MY_ACTIVITY_LIST_RESET = "my-activity/list/reset";

export const BRAND_CONFIG_UPLOAD_ALLOW_LIST = "admin/upload/allow-list";
export const BRAND_CONFIG_UPLOAD_ALLOW_LIST_ERROR =
  "admin/upload/allow-list/error";

export const AUTH_LOGIN_ERROR_RESET = "auth/login/error/reset";
export const USER_ERROR_RESET = "user/error/reset;";

export const ADD_EMAIL = "add/email";
export const ADD_EMAIL_COMPLETED = "add/email/completed";
export const ADD_EMAIL_ERROR = "add/email/error";

export const ADD_EMAIL_CHECK_VERIFICATION = "add/email/check/verification";
export const ADD_EMAIL_CHECK_VERIFICATION_ERROR =
  "add/email/check/verification/error";

export const ADD_ALTERNATE_LOGIN_CODE_RESET = "add/alternate/login/code/reset";

export const ADD_WALLET_ADDRESS = "add/wallet-address";
export const ADD_WALLET_ADDRESS_COMPLETED = "add/wallet-address/completed";
export const ADD_WALLET_ADDRESS_ERROR = "add/wallet-address/error";

export const ADD_WALLET_ADDRESS_VERIFY_SIGN = "add/wallet-address/verify/sign";

export const ADD_WALLET_ADDRESS_VERIFY_SIGN_ERROR =
  "add/wallet-address/verify/sign/error";

export const ADD_ALTERNATE_LOGIN_VERIFY_EMAIL =
  "add/alternate/login/verify/email";
export const ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_COMPLETED =
  "add/alternate/login/verify/email/completed";
export const ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_ERROR =
  "add/alternate/login/verify/email/error";

export const ADD_WALLET_ADDRESS_REJECT_SIGN = "add/wallet-address/reject/sign";

export const ADD_WALLET_ADDRESS_REJECT_SIGN_COMPLETED =
  "add/wallet-address/reject/sign/completed";

export const ADD_WALLET_ADDRESS_REJECT_SIGN_ERROR =
  "add/wallet-address/reject/sign/completed";

export const ADD_ALTERNATE_LOGIN_COMPLETED = "add/alternate/login/completed";

export const USER_ALTERNATE_WALLET_LOGIN = "user/alternate/wallet/login";

// user alternate login resend email

export const USER_ALTERNATE_EMAIL_RESEND = "user/alternate/email/resend";
export const USER_ALTERNATE_EMAIL_RESEND_COMPLETED =
  "user/alternate/email/resend/comeplted";
export const USER_ALTERNATE_EMAIL_RESEND_ERROR =
  "user/alternate/email/resend/error";
export const USER_ALTERNATE_EMAIL_RESEND_RESET =
  "user/alternate/email/resend/reset";

export const USER_SUGGESTION_SUBMISSION = "user/suggestion/submission";
export const USER_SUGGESTION_SUBMISSION_COMPLETED =
  "user/suggestion/submission/completed";
export const USER_SUGGESTION_SUBMISSION_ERROR =
  "user/suggestion/submission/error";
export const USER_SUGGESTION_SUBMISSION_ERROR_RESET =
  "user/suggestion/submission/error/reset";

export const QUEST_NEW_FORM_SUBMISSION = "quest/new/form/submission";

export const QUEST_LEAR2EARN_VIDEO_URL_SECTION =
  "quest/lear2earn/video/url/section";

export const QUEST_QUIZ_QUESTIONS_SECTION = "quest/quiz/questions/section";
export const QUEST_POLL_QUESTION_SECTION = "quest/poll/question/section";
export const QUEST_PROOF_SECTION = "quest/proof/section/submission";
export const QUEST_SURVEY_QUESTIONS_SECTION = "quest/survey/questions/section";
export const QUEST_MORE_DETAILS_FORM_SUBMISSION =
  "quest/more/details/form/submission";
export const QUEST_RESET_CURRENT_QUEST_ITEM = "quest/reset/current/quest/item";

export const QUEST_CREATE = "quest/create";
export const QUEST_CREATE_COMPLETED = "quest/create/completed";
export const QUEST_CREATE_ERROR = "quest/create/error";

export const QUESTS_LIST_GET = "quests/list/get";
export const QUESTS_LIST_GET_COMPLETED = "quests/list/get/completed";
export const QUESTS_LIST_GET_ERROR = "quests/list/get/error";

export const SET_CURRENT_QUEST_ITEM = "set/current/quest/item";

export const QUEST_UPDATE = "quest/update";
export const QUEST_UPDATE_COMPLETED = "quest/update/completed";
export const QUEST_UPDATE_ERROR = "quest/update/error";

export const QUEST_COVER_IMAGE_UPDATE = "quest/cover/image/update";
export const QUEST_COVER_IMAGE_UPDATE_COMPLETED =
  "quest/cover/image/update/completed";
export const QUEST_COVER_IMAGE_UPDATE_ERROR = "quest/cover/image/update/error";

export const USER_QUESTS_LIST_GET = "user/quest/list/get";
export const USER_QUESTS_LIST_GET_COMPLETED = "user/quest/list/get/completed";
export const USER_QUESTS_LIST_GET_ERROR = "user/quest/list/get/error";

export const MY_QUESTS_ACTIVITY_LIST_GET = "my/quest/activity/list/get";
export const MY_QUESTS_ACTIVITY_LIST_GET_COMPLETED =
  "my/quest/activity/list/get/completed";
export const MY_QUESTS_ACTIVITY_LIST_GET_ERROR =
  "my/quest/activity/list/get/error";

export const MY_QUESTS_ACTIVITY_LIST_RESET = "my/quest/activity/list/get/reset";

export const CLAIM_USER_QUEST = "claim/user/quest";
export const CLAIM_USER_QUEST_COMPLETED = "claim/user/quest/completed";
export const CLAIM_USER_QUEST_ERROR = "claim/user/quest/error";

export const CLAIMED_USER_QUEST_AMOUNT_RESET = "claim/user/quest/amount/reset";

export const QUEST_GET = "quest/get";
export const QUEST_GET_COMPLETED = "quest/get/completed";
export const QUEST_GET_ERROR = "quest/get/error";

export const LIST_ALL_USER_QUEST = "list/all/user/quest";
export const LIST_ALL_USER_QUEST_COMPLETED = "list/all/user/quest/completed";
export const LIST_ALL_USER_QUEST_ERROR = "list/all/user/quest/error";
export const LIST_ALL_USER_QUEST_RESET = "list/all/user/quest/reset";

export const UPDATE_USER_QUEST_STATUS = "update/user/quest/status";
export const UPDATE_USER_QUEST_STATUS_COMPLETED =
  "update/user/quest/status/completed";
export const UPDATE_USER_QUEST_STATUS_ERROR = "update/user/quest/status/error";

export const USER_QUIZ_QUEST_TRY_AGAIN_RESET =
  "user/quiz/quest/try/again/reset";

export const USER_QUEST_CLAIM_ERROR_RESET = "user/quest/claim/error/reset";

export const USER_QUIZ_QUEST_CLAIM_SAFETY_CHECK_LINK_ERROR_RESET =
  "user/quest/claim/safety/check/link/error/reset";

export const USER_FAKE_TWITTER_CLAIM = "user/fake/twitter/claim";
export const USER_FAKE_TWITTER_CLAIM_COMPLETED =
  "user/fake/twitter/claim/completed";
export const USER_FAKE_TWITTER_CLAIM_ERROR = "user/fake/twitter/claim/error";

export const GET_USER_REFERRAL_CODE = "get/user/referral/code";
export const GET_USER_REFERRAL_CODE_COMPLETED =
  "get/user/referral/code/completed";
export const GET_USER_REFERRAL_CODE_ERROR = "get/user/referral/code/error";

export const GET_INVITE_DETAILS = "get/invite/details";
export const GET_INVITE_DETAILS_COMPLETED = "get/invite/details/completed";
export const GET_INVITE_DETAILS_ERROR = "get/invite/details/error";

export const RESET_APP_STATE = "reset/app/state";

// Exclusive Content

export const EXCLUSIVE_CONTENT_CREATE = "exclusive-content/create";
export const EXCLUSIVE_CONTENT_CREATE_COMPLETED =
  "exclusive-content/create/completed";
export const EXCLUSIVE_CONTENT_CREATE_ERROR = "exclusive-content/create/error";

export const EXCLUSIVE_CONTENTS_LIST_GET = "exclusive-contents/list/get";
export const EXCLUSIVE_CONTENTS_LIST_GET_COMPLETED =
  "exclusive-contents/list/get/completed";
export const EXCLUSIVE_CONTENTS_LIST_GET_ERROR =
  "exclusive-contents/list/get/error";

export const EXCLUSIVE_CONTENT_UPDATE = "exclusive-content/update";
export const EXCLUSIVE_CONTENT_UPDATE_COMPLETED =
  "exclusive-content/update/completed";
export const EXCLUSIVE_CONTENT_UPDATE_ERROR = "exclusive-content/update/error";

export const EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE =
  "exclusive-content/cover/image/update";
export const EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_COMPLETED =
  "exclusive-content/cover/image/update/completed";
export const EXCLUSIVE_CONTENT_COVER_IMAGE_UPDATE_ERROR =
  "exclusive-content/cover/image/update/error";

export const EXCLUSIVE_CONTENT_GET = "exclusive-content/get";
export const EXCLUSIVE_CONTENT_GET_COMPLETED =
  "exclusive-content/get/completed";
export const EXCLUSIVE_CONTENT_GET_ERROR = "exclusive-content/get/error";

export const EXCLUSIVE_CONTENT_DELETE = "exclusive-content/delete";
export const EXCLUSIVE_CONTENT_DELETE_COMPLETED =
  "exclusive-content/delete/completed";
export const EXCLUSIVE_CONTENT_DELETE_ERROR = "exclusive-content/delete/ERROR";

export const ADMIN_IS_QUEST_APPROVAL_PENDING = "admin/is/approval/pending";

export const APPROVE_OR_REJECT_ALL_USER_QUEST =
  "admin/approve/or/reject/all/user/quest";
export const APPROVE_OR_REJECT_ALL_USER_QUEST_COMPLETED =
  "admin/approve/or/reject/all/user/quest/completed";
export const APPROVE_OR_REJECT_ALL_USER_QUEST_ERROR =
  "admin/approve/or/reject/all/user/quest/error";

export const IS_QUEST_APPROVAL_PENDING_FOR_SELECTED_QUEST =
  "is/quest/approval/pending/for/selected/quest";

export const TRANSACTIONS_STATS_DOWNLOAD = "transaction/stats/download";
export const TRANSACTIONS_STATS_DOWNLOAD_COMPLETED =
  "transactions/stats/download/completed";
export const TRANSACTIONS_STATS_DOWNLOAD_ERROR =
  "transactions/stats/download/error";

export const MEMBERS_STATS_DOWNLOAD = "members/stats/download";
export const MEMBERS_STATS_DOWNLOAD_COMPLETED =
  "members/stats/download/completed";
export const MEMBERS_STATS_DOWNLOAD_ERROR = "members/stats/download/error";

export const SAFETY_CHECK_LINK = "safety/check/link";
export const SAFETY_CHECK_LINK_COMPETED = "safety/check/link/completed";
export const SAFETY_CHECK_LINK_ERROR = "safety/check/link/error";

export const USER_SUGGESTION_OR_SUPPORT_SUBMISSION_RESET =
  "user/suggestion/or/support/submission/reset/";

export const GET_PWA_MANIFEST_DATA_COMPLETED =
  "get/pwa/manifest/data/completed";

export const GET_PWA_MANIFEST_DATA_ERROR = "get/pwa/manifest/data/error";
