import { HYDRATE } from "next-redux-wrapper";
import { Admin } from "../../models/entities/Admin";
import { User } from "../../models/entities/User";
import {
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_COMPLETED,
  ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_ERROR,
  AUTH_CHECK_VERIFICATION,
  AUTH_CHECK_VERIFICATION_COMPLETED,
  AUTH_CHECK_VERIFICATION_ERROR,
  AUTH_LOGIN_COMPLETED,
  AUTH_LOGIN_EMAIL_RESEND,
  AUTH_LOGIN_EMAIL_RESEND_COMPLETED,
  AUTH_LOGIN_EMAIL_RESEND_ERROR,
  AUTH_LOGIN_EMAIL_RESEND_RESET,
  AUTH_LOGIN_ERROR_RESET,
  AUTH_LOGIN_HALF,
  AUTH_LOGIN_HALF_COMPLETED,
  AUTH_LOGIN_HALF_DISABLED_UI,
  AUTH_LOGIN_HALF_EMAIL_SENT,
  AUTH_LOGIN_HALF_ERROR,
  AUTH_LOGIN_SHOW_MODAL_UI,
  AUTH_SIGNATURE_REJECTED,
  AUTH_VERIFY_EMAIL,
  AUTH_VERIFY_EMAIL_COMPLETED,
  AUTH_VERIFY_EMAIL_ERROR,
  AUTH_VERIFY_SIGNATURE,
  AUTH_VERIFY_SIGNATURE_ERROR,
  RESET_APP_STATE,
  USER_DISCONNECT,
  USER_FETCH_ME_COMPLETED,
} from "../actions/actions.constants";

export interface AuthState {
  nonce?: string | null;
  userID?: number;
  loading?: boolean;
  error?: string;
  initial_loading?: boolean;
  isEmailResent?: boolean | null;
  is_verified?: boolean;
  is_login_page_ui_disabled?: boolean;
  is_login_checking_modal_enabled?: boolean;
}

const initialState: AuthState = {
  nonce: null,
  userID: -1,
  loading: false,
  error: "",
  isEmailResent: null,
};
export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_APP_STATE: {
      return initialState;
    }
    case HYDRATE: {
      if (action.payload.auth) {
        // Only Hydrate these values
        const { userID } = action.payload.auth;
        return {
          ...action.payload.auth,
          userID,
        };
      }
    }
    case AUTH_LOGIN_HALF_DISABLED_UI: {
      const isDisabled = action.payload?.req?.is_disabled;
      return {
        ...state,
        is_login_page_ui_disabled: isDisabled,
      };
    }
    case AUTH_LOGIN_SHOW_MODAL_UI: {
      const showModal = action.payload?.req?.show_modal;
      return {
        ...state,
        is_login_checking_modal_enabled: !!showModal,
      };
    }
    case AUTH_CHECK_VERIFICATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case AUTH_CHECK_VERIFICATION_COMPLETED: {
      return {
        ...state,
        loading: false,
      };
    }
    case AUTH_CHECK_VERIFICATION_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case AUTH_LOGIN_ERROR_RESET: {
      return { ...state, error: "" };
    }
    case AUTH_LOGIN_HALF:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case AUTH_LOGIN_HALF_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AUTH_LOGIN_HALF_EMAIL_SENT: {
      return {
        ...state,
        error: "",
        loading: false,
      };
    }
    case AUTH_LOGIN_HALF_COMPLETED: {
      return {
        ...state,
        nonce: action.data.nonce,
        error: "",
      };
    }
    case USER_FETCH_ME_COMPLETED:
      const user = action.data.data.user as User | null;
      const admin = action.data.data.admin as Admin | null;
      let id;
      if (!!user) {
        id = user.id;
      } else {
        id = admin?.id;
      }

      return {
        ...state,
        userID: id ?? -1,
        loading: false,
      };
    case AUTH_VERIFY_SIGNATURE:
      return {
        ...state,
      };
    case AUTH_LOGIN_COMPLETED: {
      const user = action.data.data.user as User | null;
      const admin = action.data.data.admin as Admin | null;
      let id;

      if (!!user) {
        id = user.id;
      } else {
        id = admin?.id;
      }

      return {
        ...state,
        userID: id ?? -1,
        loading: false,
      };
    }
    case AUTH_VERIFY_SIGNATURE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AUTH_SIGNATURE_REJECTED:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case USER_DISCONNECT: {
      return {
        ...initialState,
      };
    }

    case AUTH_LOGIN_EMAIL_RESEND:
      return {
        ...state,
        isEmailResent: null,
      };

    case AUTH_LOGIN_EMAIL_RESEND_RESET:
      return {
        ...state,
        isEmailResent: null,
      };
    case AUTH_LOGIN_EMAIL_RESEND_COMPLETED:
      return {
        ...state,
        isEmailResent: action.payload.res.isEmailResent,
      };
    case AUTH_LOGIN_EMAIL_RESEND_ERROR:
      return {
        ...state,
        isEmailResent: null,
        error: action.payload.message,
      };

    case AUTH_VERIFY_EMAIL:
      return {
        ...state,
        loading: true,
      };

    case ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_COMPLETED:
    case AUTH_VERIFY_EMAIL_COMPLETED:
      return {
        ...state,
        is_verified: action.payload.success,
        loading: false,
      };
    case ADD_ALTERNATE_LOGIN_VERIFY_EMAIL_ERROR:
    case AUTH_VERIFY_EMAIL_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };

    default:
      return state;
  }
};
