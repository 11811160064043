import { Admin } from "../../models/entities/Admin";
import { Brand, RawBrand } from "../../models/entities/Brand";
import { BrandConfig } from "../../models/entities/BrandConfig";
import BrandSocialCredential from "../../models/entities/BrandSocialsCredentials";
import { Engagement } from "../../models/entities/Engagement";
import {
  ADD_ADMIN_WALLET_ADDRESS,
  ADD_ADMIN_WALLET_ADDRESS_COMPLETED,
  ADD_ADMIN_WALLET_ADDRESS_ERROR,
  ADMIN_LIST_GET,
  ADMIN_LIST_GET_COMPLETED,
  ADMIN_LIST_GET_ERROR,
  ADMIN_LIST_RESET,
  ADMIN_WALLET_ADDRESS_DELETE,
  ADMIN_WALLET_ADDRESS_DELETE_COMPLETED,
  ADMIN_WALLET_ADDRESS_DELETE_ERROR,
  BRAND_BANNER_UPDATE,
  BRAND_BANNER_UPDATE_COMPLETED,
  BRAND_BANNER_UPDATE_ERROR,
  BRAND_CONFIG_BULK_UPDATE,
  BRAND_CONFIG_BULK_UPDATE_COMPLETED,
  BRAND_CONFIG_BULK_UPDATE_ERROR,
  BRAND_CONFIG_UPDATE,
  BRAND_CONFIG_UPDATE_COMPLETED,
  BRAND_CONFIG_UPDATE_ERROR,
  BRAND_CONFIG_UPLOAD_ALLOW_LIST,
  BRAND_CONFIG_UPLOAD_ALLOW_LIST_ERROR,
  BRAND_FAVICON_UPDATE,
  BRAND_FAVICON_UPDATE_COMPLETED,
  BRAND_FAVICON_UPDATE_ERROR,
  BRAND_GET,
  BRAND_GET_COMPLETED,
  BRAND_GET_ERROR,
  BRAND_LATEST_TWEET_LIST_GET,
  BRAND_LATEST_TWEET_LIST_GET_COMPLETED,
  BRAND_LATEST_TWEET_LIST_GET_ERROR,
  BRAND_LOGO_UPDATE,
  BRAND_LOGO_UPDATE_COMPLETED,
  BRAND_LOGO_UPDATE_ERROR,
  BRAND_PWA_APPEARANCE_CONFIG_ACTION,
  BRAND_SHARE_IMAGE_UPDATE,
  BRAND_SHARE_IMAGE_UPDATE_COMPLETED,
  BRAND_SHARE_IMAGE_UPDATE_ERROR,
  BRAND_SOCIALS_CREDENTIALS_GET,
  BRAND_SOCIALS_CREDENTIALS_GET_COMPLETED,
  BRAND_SOCIALS_CREDENTIALS_GET_ERROR,
  BRAND_SOCIALS_CREDENTIALS_UPDATE,
  BRAND_SOCIALS_CREDENTIALS_UPDATE_COMPLETED,
  BRAND_SOCIALS_CREDENTIALS_UPDATE_ERROR,
  BRAND_UPDATE,
  BRAND_UPDATE_COMPLETED,
  BRAND_UPDATE_ERROR,
  EDIT_ADMIN,
  EDIT_ADMIN_COMPLETED,
  EDIT_ADMIN_ERROR,
  GET_PWA_MANIFEST_DATA_COMPLETED,
  USER_FAKE_TWITTER_CLAIM,
  USER_FAKE_TWITTER_CLAIM_COMPLETED,
  USER_FAKE_TWITTER_CLAIM_ERROR,
} from "./actions.constants";

export const brandGetAction = () => ({
  type: BRAND_GET,
});

export const brandGetCompletedAction = (payload: {
  res: { brand: RawBrand };
}) => ({
  type: BRAND_GET_COMPLETED,
  payload,
});

export const brandGetErrorAction = (payload: { message: string }) => ({
  type: BRAND_GET_ERROR,
  payload,
});

export const brandUpdateAction = (payload: {
  id: string;
  req: {
    title: string;
    privacy_policy_url: string | null;
    terms_and_condition_url: string | null;
    currency: string | null;
    website_url: string | null;
  };
}) => ({
  type: BRAND_UPDATE,
  payload,
});

export const brandUpdateCompletedAction = (payload: {
  res: {
    brand: Brand;
  };
}) => ({
  type: BRAND_UPDATE_COMPLETED,
  payload,
});

export const brandUpdateErrorAction = (payload: { message: string }) => ({
  type: BRAND_UPDATE_ERROR,
  payload,
});

export const brandConfigUpdateAction = (payload: {
  req: { config_id: any; value: any };
}) => ({
  type: BRAND_CONFIG_UPDATE,
  payload,
});

export const brandConfigUpdateCompletedAction = (payload: {
  res: { config: BrandConfig; pwaDataLoading?: boolean };
}) => ({
  type: BRAND_CONFIG_UPDATE_COMPLETED,
  payload,
});

export const brandConfigUpdateErrorAction = (payload: { message: string }) => ({
  type: BRAND_CONFIG_UPDATE_ERROR,
  payload,
});

export const brandLogoUpdateAction = (payload: {
  id: number;
  req: { logo_image_object_url: string };
}) => ({
  type: BRAND_LOGO_UPDATE,
  payload,
});

export const brandLogoUpdateCompletedAction = (payload: {
  res: { brand_config: BrandConfig };
}) => ({
  type: BRAND_LOGO_UPDATE_COMPLETED,
  payload,
});

export const brandLogoUpdateErrorAction = (payload: { message: string }) => ({
  type: BRAND_LOGO_UPDATE_ERROR,
  payload,
});

export const brandShareImageUpdateAction = (payload: {
  id: number;
  req: { share_image_object_url: string };
}) => ({
  type: BRAND_SHARE_IMAGE_UPDATE,
  payload,
});

export const brandShareImageUpdateCompletedAction = (payload: {
  res: { brand_config: BrandConfig };
}) => ({
  type: BRAND_SHARE_IMAGE_UPDATE_COMPLETED,
  payload,
});

export const brandShareImageUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_SHARE_IMAGE_UPDATE_ERROR,
  payload,
});

export const brandFaviconUpdateAction = (payload: {
  id: number;
  req: { favicon_image_object_url: string };
}) => ({
  type: BRAND_FAVICON_UPDATE,
  payload,
});

export const brandFaviconUpdateCompletedAction = (payload: {
  res: { brand_config: BrandConfig };
}) => ({
  type: BRAND_FAVICON_UPDATE_COMPLETED,
  payload,
});

export const brandFaviconUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_FAVICON_UPDATE_ERROR,
  payload,
});

export const brandBannerUpdateAction = (payload: {
  id: number;
  req: { banner_image_object_url: string };
}) => ({
  type: BRAND_BANNER_UPDATE,
  payload,
});

export const brandBannerUpdateCompletedAction = (payload: {
  res: { brand_config: BrandConfig };
}) => ({
  type: BRAND_BANNER_UPDATE_COMPLETED,
  payload,
});

export const brandBannerUpdateErrorAction = (payload: { message: string }) => ({
  type: BRAND_BANNER_UPDATE_ERROR,
  payload,
});

export const addAdminWalletAddressAction = (payload: {
  req: {
    username?: string;
    wallet_address?: string;
    email?: string;
  };
}) => ({
  type: ADD_ADMIN_WALLET_ADDRESS,
  payload,
});

export const addAdminWalletAddressCompletedAction = (payload: {
  res: {
    admin: Admin;
  };
}) => ({
  type: ADD_ADMIN_WALLET_ADDRESS_COMPLETED,
  payload,
});

export const addAdminWalletAddressErrorAction = (payload: {
  message: string;
}) => ({
  type: ADD_ADMIN_WALLET_ADDRESS_ERROR,
  payload,
});

export const editAdminAction = (payload: {
  id: any;
  req: {
    username?: string;
    wallet_address?: string;
    email?: string;
  };
}) => ({
  type: EDIT_ADMIN,
  payload,
});

export const editAdminCompletedAction = (payload: {
  res: {
    admin: Admin;
  };
}) => ({
  type: EDIT_ADMIN_COMPLETED,
  payload,
});

export const editAdminErrorAction = (payload: { message: string }) => ({
  type: EDIT_ADMIN_ERROR,
  payload,
});

export const brandSocialsCredentialsGetAction = (payload: {}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_GET,
  payload,
});

export const brandSocialsCredentialsGetCompletedAction = (payload: {
  res: { brand_social_credentials: BrandSocialCredential };
}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_GET_COMPLETED,
  payload,
});

export const brandSocialsCredentialsGetErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_GET_ERROR,
  payload,
});

export const brandSocialsCredentialsUpdateAction = (payload: {
  req: {
    provider: string;
    access_token?: any;
    client_id?: any;
    client_secret?: any;
    is_paid?: boolean;
    // For Firebase credentials
    clientEmail?: string;
    privateKey?: string;
    projectId?: string;
    // For ReCaptcha credentials
    site_key?: string;
    secret_key?: string;
  };
}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_UPDATE,
  payload,
});

export const brandSocialsCredentialsUpdateCompletedAction = (payload: {
  res: {
    brand_social_credential: BrandSocialCredential;
  };
}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_UPDATE_COMPLETED,
  payload,
});

export const brandSocialsCredentialsUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_SOCIALS_CREDENTIALS_UPDATE_ERROR,
  payload,
});

export const adminListGetAction = (payload: {
  req?: {
    search?: string;
  };
}) => ({
  type: ADMIN_LIST_GET,
  payload,
});

export const adminListGetCompletedAction = (payload: {
  res: {
    admins: Admin[];
  };
}) => ({
  type: ADMIN_LIST_GET_COMPLETED,
  payload,
});

export const adminListGetErrorAction = (payload: { message: string }) => ({
  type: ADMIN_LIST_GET_ERROR,
  payload,
});

export const adminWalletAddressDeleteAction = (payload: {
  req: {
    id: number;
  };
}) => ({
  type: ADMIN_WALLET_ADDRESS_DELETE,
  payload,
});

export const adminWalletAddressDeleteCompletedAction = (payload: {
  id: any;
}) => ({
  type: ADMIN_WALLET_ADDRESS_DELETE_COMPLETED,
  payload,
});

export const adminWalletAddressDeleteErrorAction = (payload: {
  message: string;
}) => ({
  type: ADMIN_WALLET_ADDRESS_DELETE_ERROR,
  payload,
});

export const adminListResetAction = () => ({
  type: ADMIN_LIST_RESET,
});

export const brandLatestTweetListGetAction = (payload: {
  req: {
    urls: string[];
  };
}) => ({
  type: BRAND_LATEST_TWEET_LIST_GET,
  payload,
});

export const brandLatestTweetListGetCompletedAction = (payload: {
  res: {
    tweets: Engagement[];
  };
}) => ({
  type: BRAND_LATEST_TWEET_LIST_GET_COMPLETED,
  payload,
});

export const brandLatestTweetListGetErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_LATEST_TWEET_LIST_GET_ERROR,
  payload,
});

export const brandConfigUploadAllowlistAction = (payload: {
  req: {
    file: File;
    configKey: string;
  };
}) => ({
  type: BRAND_CONFIG_UPLOAD_ALLOW_LIST,
  payload,
});
export const brandConfigUploadAllowlistErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_CONFIG_UPLOAD_ALLOW_LIST_ERROR,
  payload,
});

export const brandPwaAppearanceConfigAction = (payload: {
  req: {
    name: string;
    short_name: string;
    description: string;
    scope: string;
    display: string;
    icon: {
      image: any;
      is_new_uploaded: boolean;
    };
    start_url: string;
    background_color: string;
    theme_color: string;
  };
}) => ({
  type: BRAND_PWA_APPEARANCE_CONFIG_ACTION,
  payload,
});

// fake twitter action called should be modified according to the need

export const userFakeTwitterClaimsAction = (payload: any) => ({
  type: USER_FAKE_TWITTER_CLAIM,
  payload,
});

export const userFakeTwitterClaimsActionCompleted = (payload: any) => ({
  type: USER_FAKE_TWITTER_CLAIM_COMPLETED,
  payload,
});
export const userFakeTwitterClaimsActionError = (payload: any) => ({
  type: USER_FAKE_TWITTER_CLAIM_ERROR,
  payload,
});

export const getPWAManifestDataCompleted = (payload: { data: any }) => ({
  type: GET_PWA_MANIFEST_DATA_COMPLETED,
  payload,
});

export const brandConfigBulkUpdateAction = (payload: {
  req: {
    configs: { config_id: number | undefined; value: any }[];
  };
}) => ({
  type: BRAND_CONFIG_BULK_UPDATE,
  payload,
});

export const brandConfigBulkUpdateCompletedAction = (payload: {
  res: { configs: BrandConfig[] };
}) => ({
  type: BRAND_CONFIG_BULK_UPDATE_COMPLETED,
  payload,
});

export const brandConfigBulkUpdateErrorAction = (payload: {
  message: string;
}) => ({
  type: BRAND_CONFIG_BULK_UPDATE_ERROR,
  payload,
});
